import { Box, BoxProps, styled } from '@mui/material'

import { AliasBoolTypes } from '@/types/baseTypes'

export const Home = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  maxWidth: '1240px',
  alignSelf: 'center',
  width: '100%',
  padding: '0 20px',
  [theme.breakpoints.down('lg')]: {
    padding: '0',
  },
}))

export const NavBlock = styled(Box)(({ theme }) => ({
  paddingTop: '86px',
  paddingBottom: '86px',
  // paddingInlineStart: '123px',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}))

interface NavIconBlockProps extends BoxProps {
  target?: AliasBoolTypes
}

export const NavIconBlock = styled(Box)<NavIconBlockProps>(({ theme, target }) => {
  return {
    display: 'flex',
    svg: {
      path: {
        fill: target === 'true' && theme.palette.ui.primary.main,
      },
    },
  }
})

export const NavColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '276px',
  gap: '29px',
}))

export const NavRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  cursor: 'pointer',
}))

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingTop: '70px',
  paddingBottom: '70px',
  // paddingInlineEnd: '123px',
  paddingInlineStart: '30px',
  width: '100%',
  flexDirection: 'column',
  minHeight: '547px',

  [theme.breakpoints.down('lg')]: {
    minHeight: 'auto',
    padding: '20px 20px 24px 20px',
    overflow: 'hidden',
  },
}))

export const MultiBlock = styled(Box)(({ theme }) => ({
  marginBottom: '30px',
  [theme.breakpoints.down('lg')]: {
    marginBottom: '16px',
  },
}))

interface WrapperAllCategoriesIconProps extends BoxProps {
  target?: AliasBoolTypes
}

export const WrapperAllCategoriesIcon = styled(Box)<WrapperAllCategoriesIconProps>(({ theme, target }) => ({
  display: 'flex',
  svg: {
    path: {
      fill: target === 'true' ? theme.palette.ui.primary.main : theme.palette.ui.grey[900],
      transition: 'fill 0.2s linear',
    },
  },
}))

export const PaginationRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '50px',
  direction: 'ltr',
  [theme.breakpoints.down('lg')]: {
    marginTop: '25px',
  },
}))
