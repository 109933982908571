import { Box, Divider, Pagination, Typography, useTheme } from '@mui/material'
import { GetServerSideProps } from 'next'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'

import { Catalog } from '@/components'
import { MetaLayout } from '@/components/Layout/MetaLayout/MetaLayout'
import { MultiSearch } from '@/components/uiKit'

import { BASE_URL } from '@/constants/env'

import { useSiteIdContext } from '@/context/SiteIdContext'

import { client } from '@/graphql/client'
import {
  CategoryEntity,
  GetCategoriesQueryResult,
  GetProductsQueryResult,
  Maybe,
  ProductsWithCountSchema,
  useGetCurrentUserQuery,
  useGetProductsLazyQuery,
} from '@/graphql/generated'
import { GET_CATEGORIES, GET_PRODUCTS } from '@/graphql/queries'

import { useCurrentLanguage } from '@/hooks/useCurrentLanguage'

import { AllProdIcon } from '@/icons'

import { useDebounce } from '@/utils'
import { hasAuthToken } from '@/utils/authToken'
import { getTranslations } from '@/utils/i18n-cache'

import * as S from './index.styled'

type TransformFuncReturn = {
  value: string
  label: string
  icon: React.ReactNode
  targetIcon: React.ReactNode
  svgCode: Maybe<string> | undefined
}

interface Props {
  allCategories: GetCategoriesQueryResult
  products: GetProductsQueryResult
  locale: string
}

const Home = (props: Props) => {
  const { siteId } = useSiteIdContext()
  const { isENLang } = useCurrentLanguage()
  const [countriesValue, setCountriesValue] = useState('')
  const [searchText, setSearchText] = useState('')
  const [category, setCategory] = useState('')
  const [countPagination, setCountPagination] = useState(0)
  const [limits] = useState(12)
  const [page, setPage] = useState(1)
  const { t } = useTranslation()

  const theme = useTheme()

  const [query, { data: productsData, loading }] = useGetProductsLazyQuery()
  const user = useGetCurrentUserQuery({ skip: !hasAuthToken() })

  const searchTextDebounce = useDebounce(searchText)

  const allCategoriesData = props.allCategories.data?.getCategories.categories

  const transformCategoriesData = (isEn: boolean, data?: CategoryEntity[]): TransformFuncReturn[] => {
    const res = [
      {
        value: '',
        label: 'All Categories',
        icon: <AllProdIcon color="#000000" />,
      },
    ] as TransformFuncReturn[]

    data &&
      data.forEach((category: CategoryEntity) => {
        const src = `${BASE_URL}/uploads/images/categories/${category.icon}`
        const srcTarget = `${BASE_URL}/uploads/images/categories/target_${category.icon}`

        res.push({
          value: category.id,
          label: category[isEn ? 'nameEN' : 'nameAR'] as string,
          icon: category.icon ? src : null,
          targetIcon: category.icon ? srcTarget : null,
          svgCode: category.svgCode,
        })
      })

    return res
  }

  // todo: remove
  // @ts-ignore
  const categories = transformCategoriesData(isENLang, allCategoriesData)

  const catalogData = productsData?.getProducts

  const handleFilterClick = (value: string) => {
    setCategory(value)
    resetPagination()
  }

  const handleSearchText = (value: string) => {
    setSearchText(value)
    resetPagination()
  }

  const handleCountryChange = (country: string) => {
    setCountriesValue(country)
    resetPagination()
  }

  const resetPagination = () => {
    setCountPagination(0)
    setPage(1)
  }

  useEffect(() => {
    if (catalogData) {
      setCountPagination(Math.ceil(catalogData?.count / limits))
    }
  }, [limits, catalogData, catalogData?.count])

  useEffect(() => {
    if (!user.error && user.data?.getCurrentUser?.country) {
      setCountriesValue(user.data.getCurrentUser.country)
    }
  }, [user])

  useEffect(() => {
    query({
      variables: {
        input: {
          limit: limits,
          search: searchTextDebounce,
          categoryId: category ? +category : null,
          country: countriesValue,
          offset: limits * (page - 1),
          siteId: Number(siteId),
        },
      },
      fetchPolicy: 'network-only',
    })
  }, [category, limits, query, searchTextDebounce, page, countriesValue, siteId])

  return (
    <MetaLayout title="Web Market">
      <S.Home>
        <S.ContentWrapper>
          <S.NavBlock>
            <S.NavColumn>
              {categories.map((item, index) => {
                const isCategory = category === item.value

                return (
                  <S.NavRow key={index} onClick={() => handleFilterClick(item.value)}>
                    <S.NavIconBlock>
                      {item.icon ? (
                        !(item.icon as any)['$$typeof'] ? (
                          <img src={`${item.icon}`} alt={item.label} width={24} height={24} />
                        ) : (
                          item.icon
                        )
                      ) : (
                        <Box sx={{ width: '24px', height: '24px' }} />
                      )}
                    </S.NavIconBlock>

                    <Typography variant="body1" color={isCategory ? theme.palette.ui.primary.main : ''} component="p">
                      {item.label}
                    </Typography>
                    {/*! when you need the current component just uncomment this code*/}
                    {/*{item.new && <BadgeCustom marginInlineStart="56px" />}*/}
                  </S.NavRow>
                )
              })}
            </S.NavColumn>
          </S.NavBlock>

          <Divider orientation="vertical" />

          <S.Content>
            <S.MultiBlock>
              <MultiSearch
                loading={false}
                countriesValue={countriesValue}
                textValue={searchText}
                categoryFilterValue={category}
                categoryFilterData={categories}
                placeholderTextField={`${t('app.product_search')}...`}
                setCategoryFilterData={handleFilterClick}
                setTextValue={handleSearchText}
                setCountriesValue={handleCountryChange}
              />
            </S.MultiBlock>

            <Catalog loading={loading} data={catalogData as ProductsWithCountSchema} />

            <S.PaginationRow>
              {countPagination > 1 && (
                <Pagination
                  count={countPagination}
                  shape="rounded"
                  color="primary"
                  page={page}
                  onChange={(_, value) => setPage(value)}
                />
              )}
            </S.PaginationRow>
          </S.Content>
        </S.ContentWrapper>
      </S.Home>
    </MetaLayout>
  )
}

export const getServerSideProps: GetServerSideProps = async ({ locale = 'en' }) => {
  try {
    const [allCategories] = await Promise.all([
      client.query<GetCategoriesQueryResult>({
        query: GET_CATEGORIES,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            limit: Infinity,
            offset: 0,
          },
        },
      }),
    ])

    return {
      props: {
        ...(await getTranslations(locale)),
        allCategories,
        locale,
      },
    }
  } catch {
    return {
      notFound: true,
    }
  }
}

export default Home
